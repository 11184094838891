<template>
  <div>
    <status class="mt-5" @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
    <v-row class="grey lighten-5">
      <v-col cols="4" v-for="(report, i) in summaryReports" :key="i" :class="{'col-12' : $vuetify.breakpoint.xs, 'col-6' : $vuetify.breakpoint.sm}">
        <v-card>
          <v-card-title class="card-title pb-2">{{report.name}}</v-card-title>
          <v-card-text class="summary-reports-v-card-text">{{report.description}}</v-card-text>
          <v-card-actions>

              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="show =  show === report.id ? false : report.id "
              >
                <v-icon>{{ show  === report.id ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>
            <v-expand-transition name="fade">
              <div v-show="show === report.id">
                <v-divider></v-divider>
                <v-card-text dense class="py-0 px-3">
                  <v-row>
                    <v-col :class="{'pl-5': report.end_date}" style="border-bottom: 1px solid #e2e2e2;">
                        <humming-bird-datepicker
                          v-model="report.date"
                          :default_today="true"
                          :label="'Start Date'"
                          :adjust-label="true"
                          :solo="report.end_date ? false : true"
                        >
                        </humming-bird-datepicker>
                    </v-col>
                    <v-col v-if="report.end_date" style="border-bottom: 1px solid #e2e2e2;">
                        <humming-bird-datepicker
                          v-model="report.end_date"
                          :default_today="true"
                          :label="'End Date'"
                          :adjust-label="true"
                          :solo="false"
                        >
                        </humming-bird-datepicker>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col  style="border-bottom: 1px solid #e2e2e2;">
                      
                      <v-select
                        :items="properties"
                        item-value="id"
                        flat
                        hide-details
                        dense
                        solo
                        v-model="report.property_id"
                        placeholder="Choose Property"
                        @change="getGroupingProfiles(report, i)"
                      >

                        <template v-slot:selection="{ item, index }">
                          <span v-if="item.number" class="mr-1">{{item.number}} -</span>{{item.Address.city}} - {{item.Address.address}}
                        </template>
                        <template v-slot:item="{ item, index }">
                          <span v-if="item.number" class="mr-1">{{item.number}} -</span>{{item.Address.city}} - {{item.Address.address}}
                        </template>

                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row v-if="hasGroupingProfile(report)">
                    <v-col  style="border-bottom: 1px solid #e2e2e2;">
                      <v-select
                        :disabled="!report.space_groups.length"
                        :items="report.space_groups"
                        item-value="id"
                        item-text="name"
                        flat
                        hide-details
                        dense
                        solo
                        v-model="report.unit_group_profile_id"
                        placeholder="Choose Space Group"
                      >
                      </v-select>
                    </v-col>
                  </v-row>



                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-row class="pr-1" style="justify-content: flex-end">
                      <hb-btn
                      color="primary"
                      class="mr-2 my-1 float-right"
                      :loading="show_loading_spinner"
                      @click="downloadReport(report, 'xlsx')"
                      v-if="report.download_xls"
                      >
                        Download Spreadsheet</hb-btn>
                      <hb-btn
                        class="mr-2 my-1 float-right"
                        color="primary"
                        :loading="isLoading(report.id)"
                        @click="downloadReport(report, 'pdf')"
                      >
                        Download PDF
                      </hb-btn>
                  </v-row>
                  <!-- <hb-btn
                    color="primary"
                    @click="goToReport(report)"
                  >
                    Web View
                  </hb-btn> -->



                </v-card-actions>
              </div>
            </v-expand-transition>

          </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/babel">
import moment from 'moment';
import { mapGetters } from 'vuex';

import api from '../../assets/api.js';
import HummingBirdDatepicker from '../assets/HummingbirdDatepicker';
import Status from '../includes/Messages.vue';
import { notificationMixin } from  '../../mixins/notificationMixin.js';
import { downloadFile } from '../../utils/common.js';

export default {
  name: "SummaryReports",
  mixins: [ notificationMixin ],
    components: {
      HummingBirdDatepicker,
      Status
    },
    data() {
      return {
        show_loading_spinner : false,
        show: false,
        spaceGroups: [],
        summaryReports: [

          { id: 1, name: "Management Summary Report", type: "management-summary", description: "The Management Summary report provides a bird's eye view of a facility. Quickly look at all the information most relevant to a manager, such as occupancy, rental activity, delinquency rate, and more.", date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 2, name: "Close of Day Deposits Report", download_xls: true, type: "daily-deposits",description: "The Close of Day Deposits report provides information about the total deposits your facility received in a day. Your report will break down how much of your deposits came from each payment method you accept.",  date: moment().format('YYYY-MM-DD'), property_id: '' },
          //{ id: 3, name: "Autopay",  type: "auto-pay", description: "The Autopay report displays payments set up using autopay. This report shows the payment methods that are set up for autopay and the next bill date on the lease.  It is not historical payments.", date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 4, name: "Gate Access Report", download_xls: true,  type: "gate-access",description: "The Gate Access report provides your tenant's gate codes, their balances, and how many days late they are.",  date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 5, name: "AR Aging Report", download_xls: true,  type: "account-receivable-aging", description: "The AR Aging report provides a list of delinquent tenants. You'll quickly see the amount of time that your tenants have been delinquent, their total unpaid amount, and their move out date.", date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 6, name: "Transfer Report", download_xls: true,  type: "transfer", description: "The Transfer report provides information about the tenants who have transferred within your facility. You'll receive all the information about a transfer, such as the space a tenant transferred into and out of, transfer in and transfer out balance, and the reason they transferred.", date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 7, name: "Move-in/Move-out Activity Report", download_xls: true,  type: "rental-activity", description: "This report provides information about your tenant's move-in and move out activity. It provides how information about how much a space was rented for and how long it was vacant before someone moved in, how long a space was occupied when a tenant moves out, and more. Transfers are not part of this report.", date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 8, name: "AR Aging - Active Tenants Report", download_xls: true,  type: "delinquencies", description: "The Delinquencies report provides a list of delinquent tenants. You'll quickly see the amount of time that your tenants have been delinquent, their total unpaid amount, and their move out date.", date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 9, name: "Space Activity Report", download_xls: true,  type: "space-activity", description: "The Space Activity report tracks the activity of all your spaces. Use this report to view who has moved in, moved out, transferred in, or transferred out of certain spaces in a given time frame.", date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 10, name: "MSR Detail - AutoPay Enrollment Report", type: "msr-autopay-enrollment", description: "View all active tenants currently enrolled in AutoPay. The information in this report is based on data from your Management Summary Report (MSR).", date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 11, name: "MSR Detail - Protection Plan/Insurance Enrollment Report",  type: "protection-plan", description: "View active tenants that have a protection/insurance plan. The information in this report is based on data from your Management Summary Report (MSR). ", date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 12, name: "MSR Detail - Write Offs Report",download_xls: true,  type: "write-offs", description: "View a list of write offs, based on invoices during a selected date range. The information in this report is based on data from your Management Summary Report (MSR).", date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 13, name: "MSR Detail - Applied Credits Report", download_xls: true, type: "applied-credits", description: "View a list of credits applied to invoices during a specified date range. The information in this report is based on data from your Management Summary Report (MSR).", date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 14, name: "MSR Detail - Discounts/Promotions Report",  type: "promotion", description: "The Space Activity report tracks the activity of all your spaces. Use this report to view who has moved in, moved out, transferred in, or transferred out of certain spaces in a given time frame.", date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 15, name: "MSR Detail - Overlocked Spaces Report",  type: "overlocked-spaces", description: "View tenants with overlocked spaces for a selected date and facility. The information in this report is based on data from your Management Summary Report (MSR).", date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 16, name: "MSR Detail - Net Revenue and Projected Income Report",  type: "net-revenue-projected-income", description: "View the net revenue and projected income of a specific facility and date range. The products and services offered at your facility are broken out to show how much each product or service is generating. The information in this report is based on data from your Management Summary Report (MSR). ", date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 18, name: "MSR Detail - Rent Change Summary Report",  type: "rent-change-summary", description: "View a list of tenants who have experienced a change to their rent in the past two years. Use this report to quickly see how much the tenants' rent changed during a specific time frame. The information in this report is based on data from your Management Summary Report (MSR).", date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 17, name: "Payments by Product Type Report", download_xls: true, type: "payments-by-product-type", description: "View your revenue broken down by product type to keep track of how much revenue each product generates during a selected time frame.", date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 19, name: "MSR Detail - Liabilities Summary Report",  type: "liabilities-summary", description: "View a summary of a facility's liabilities during a selected period of time. The information in this report is based on data from your Management Summary Report (MSR) to ensure the information found in this report matches the information found within the MSR.", date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 20, name: "Cash Audit Report", download_xls: true, type: "cash-audit", description: "This report will show the total amount of payment by the product type. Product type is past due rent, current rent, prepaid rent, insurance/protection, admin fees, late fees, lien fees, recurring fees, other fees, tax, merchandise, auction, security deposits, misc deposits.", date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 21, name: "MSR Detail - Space Occupancy Report",  type: "occupancy-summary", description: "View a list of occupied and vacant spaces for a specific date and facility. The information in this report is based on data from your Management Summary Report (MSR) to ensure the information found in this report matches the information found within the MSR. ", date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 22, name: "MSR Detail - Lead Activity Report",  type: "lead-summary", description: "View the activity of your leads, organized by their source such as web, walk-in, phone, or other. The information in this report is based on data from your Management Summary Report (MSR) to ensure the information found in this report matches the information found within the MSR. ", date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 23, name: "Financial Summary Report", download_xls: true, type: "financial-summary", description: "View how much your properties have charged tenants, how many payments you've received, and how many credits you've applied during a selected period. ", date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 24, name: "Occupancy Statistics Report", download_xls: false, type: "occupancy-statistics-report", description: "View detailed information about your property's occupancy statistics and financial metrics, organized by space groups.", date: moment().format('YYYY-MM-DD'),  property_id: '', unit_group_profile_id: '', space_groups: [] },
          { id: 25, name: "Payment Transaction Report", download_xls: true, type: "payment-processing", description: "View a list of payment transactions done", date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), property_id: '' },
          { id: 26, name: "Portfolio KPI", type: "store-kpi", description: "The Portfolio KPI report provides a bird’s eye view of the performance of the company and its properties.", date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), property_id: '' },
		      { id: 27, name: "Payouts Report", download_xls: true, type: "payouts", description: "View the list of payouts", date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), property_id: '' }
          // { id: 8,  name: "Occupancy Statistics",  type: "occupancy-statistics", description: "The Occupancy Statistics report allows you to look at occupancy related data for any of your facilities. Select one of your facilities and you'll receive a report containing all of your unit sizes and categories, along with the percentage of those units that are occupied and vacant. Leverage these reports to optimize unit categories that might be underperforming.", date: moment().format('YYYY-MM-DD'), property_id: '' },
          // { id: 5, name: "Rental Activity By Space Type",  type: "rental-activity", description: "Rental Activity By Space Type Report description." },
        ].sort((a,b) => a.name.localeCompare(b.name))
      };
    },
    created(){
      if(this.properties.length === 1){
          for(let i = 0; i < this.summaryReports.length; i++){
              this.summaryReports[i].property_id = this.properties[0].id
          }
          this.getGroupingProfiles(this.summaryReports[19], 19)
      }
    },
    computed:{
      ...mapGetters({
          properties: 'propertiesStore/filtered',
          primaryRoleId: 'authenticationStore/getPrimaryRoleId'
      })
    },
    methods: {

      hasGroupingProfile(report){
        console.log("report.unit_group_profile_id", report.unit_group_profile_id)
        return typeof report.unit_group_profile_id !== 'undefined';
      }, 
      async getGroupingProfiles(report, i){
        this.spaceGroups = [];
        if(!this.hasGroupingProfile(report)) return;
        let r = await api.get(this, api.PROPERTIES + report.property_id + "/space-groups");
        this.summaryReports[i].space_groups = r.spaceGroups;
      }, 
      goToReport(report) {
          if(!report.date || !report.property_id){
              return this.errorSet(this.$options.name, "Please enter a date and a property to view this report.");
          }

        try {

            let routeData = this.$router.resolve("/reports.html?type=" + report.type + "&date=" + report.date + '&property_id=' + report.property_id + (report.end_date ? "&end_date=" + report.end_date : ''));
            window.open(routeData.href, '_blank');
        } catch (err) {}
      },
      async downloadReport(report, format) {
        if(!report.date || !report.property_id){
          return this.errorSet(this.$options.name, "Please enter a date and a property to view this report.");
        }
        if(this.hasGroupingProfile(report) && !report.unit_group_profile_id){
          return this.errorSet(this.$options.name, "Please select a report group.");
        }
        let timeZone = moment.tz.guess();
        
        
        var data = {
          type: report.type,
          format,
          date: report.date,
          property_id:  report.property_id,
          timeZone: timeZone,
          name:report.name,
          unit_group_profile_id: report.unit_group_profile_id,
          roles_id: [this.primaryRoleId]
        };

        if(report.end_date) data.end_date = report.end_date;
        if(format === 'pdf'){
          let response = await api.post(this, api.REPORTS + 'download-pdf', data, report.id);

          if(response.socket) {
            const payload = { 
              type: 'document_download', 
              title: 'Generating Document',
              persistent: true,
              loading: true,
              description: 'We are generating your document, it should begin downloading shortly.'
            }
            this.showMessageNotification(payload);
          } else {
            downloadFile({
              content_type: 'application/pdf',
              file: response.data,
              filename: report.name,
              url: response.data.url,
            });
          }
        }else{
          this.show_loading_spinner = true;
          let url = api.REPORTS + 'download-xlsx';
          try{
            const response = await this.$http.post(url, data);
            const downloadFileName = `${report.name} (${moment(report.date).format("MM-DD-YYYY")}${report.end_date ? " - " + moment(report.end_date).format("MM-DD-YYYY") : ""})`;
            downloadFile({
              file: response.data,
              filename: downloadFileName,
              url: response.data.url,
            });
            this.successSet(this.$options.name, "Your download should begin shortly.");
            this.show_loading_spinner = false;
          } catch(err){
            this.show_loading_spinner = false;
            this.errorSet(this.$options.name,err);
          }
        }
        
      }
  }
};
</script>
<style lang="scss" scoped>
.card-title {
  font-size: 14px;
  line-height: 24px;
  color: #212b36;
}
.summary-reports-v-card-text {
  height:114px;
  overflow:auto;
  line-height:16px;
}
.card-description {
  font-size: 12px;
  line-height: 16px;
  color: #474f5a;
}
.btn-style {
  font-weight: normal;
  letter-spacing: normal;
}
.secondary-button {
  background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
  border: 1px solid #c4cdd5;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
}
.primary-button {
  background: linear-gradient(180deg, #47c0bf -70%, #00848e 126.25%);
  border: 1px solid #00848e;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
  color: #ffffff;
}
.cancel-button {
  font-weight: normal;
  color: #00848E;
}
</style>
