<template>
  <div>
    <v-row class="grey lighten-5">
      <v-col cols="4" v-for="(report, i) in sorted_list" :key="i" :class="{'col-12' : $vuetify.breakpoint.xs, 'col-6' : $vuetify.breakpoint.sm}">
        <v-card>
          <v-card-title class="card-title pb-2">{{ report.name === 'Autopay' ? 'AutoPay' : report.name }}</v-card-title>
          <v-card-text class="reports-library-v-card-text">{{report.description}}</v-card-text>
          <v-card-actions class="pb-4 pl-4">
            <hb-btn
              color="primary"
              @click="goToReport(report)"
            >Run Report</hb-btn>
  <!--          <v-btn-->
  <!--            class="text-capitalize primary-button btn-style"-->
  <!--            @click.stop="startEditing(item)"-->
  <!--          >-->
  <!--            <span v-if="report.id">Add to my Reports</span>-->
  <!--            <span v-else>Added</span>-->
  <!--          </v-btn>-->
            <!-- <button class="w-button btn-sm tertiary-btn trash" @click="deleteItem(report)">
              <span class="icon"></span>
            </button>-->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/babel">
export default {
  name: "ReportLibrary",
  data() {
    return {
        report_library: [
            // { name: "Active Tenants", type: "active-tenants", key: 'active_tenants',  description: "Rent Roll." },
            // { name: "Active Leads", type: "active-leads", key: 'active_tenants', description: "Rent Roll." },
            // { name: "Spaces", type: "spaces", key: 'spaces', description: "Rent Roll." },
            { name: "Retired Leads", type: "retired-leads", key: 'retired_leads', description: "This report gives details about the retired leads and the person who has retired." },
            { name: "Rent Roll", type: "rent-roll", key: 'rent_roll', description:  "The Rent Roll report provides detailed information about your properties, tenants, and their associated rents." },
            { name: "Rent Roll with all Spaces", type: "rent-roll-with-all-spaces", key: 'rent_roll_with_all_spaces', description: "The Rent Roll with All Spaces report provides detailed information about your properties, tenants and their associated rents for all space status." },
            // { name: "Failed Payments", type: "failed-payments", key: 'failed_payments', description: "Rent Roll." },
            // { name: "Vacancy Report", type: "vacancy-report", key: 'vacancy_report',  description: "Rent Roll." },
            { name: "Payments", type: "payments", key: 'payments', description: "The Payments report allows you to track and verify payment details." },
            { name: "Payments and Credits", type: "payments-and-credits", key: 'payments_and_credits', description: "The Payments report allows you to track and verify payment and credit details." },
            { name: "Invoices", type: "invoices", key: 'invoices', description:  "The Invoices report provides the descriptive charges of items associated with a tenant's account." },
            { name: "Accounts Receivable",  key: 'accounts_receivable', type: "accounts-receivable", description: "The Accounts Receivables report returns invoice activity for a tenant and displays how long they have been outstanding." },
            { name: "Walk Through Audit", type: "walk-through-audit", key: 'walk-through-audit', description: "The Walk Through Audit report allows you to examine all units and inspect their statuses based on a physical walk through of the property." },
            { name: "Prepaid Rent", type: "prepaid-rent",  key: 'prepaid_rent', description: " The Prepaid Rent report displays the amount of future rent your tenants have paid in advance for a given rental period. This is a liabilities report." },
            { name: "Unapplied Payments", type: "unapplied-payments",  key: 'unapplied_payments', description: "The Unapplied Payments report shows open payments for customers that haven't been applied to an invoice. This is a liabilities report." },
            { name: "Sales Tax Payments", type: "sales-tax-payments", key: 'sales_tax_payments', description: "Sales Tax Payments report shows all the payments allocated to Sales Tax for all your charges." },
            { name: "Sales Tax Charges", type: "sales-tax", key: 'sales_tax_charges', description: "The Sales Tax Charges report shows the sales tax charges for all your spaces." },
            { name: "Spaces Overlock or Unlock", type: "overlock", key: 'overlock', description: "The Spaces Overlock/Unlock report displays the status of your tenant's space to signify whether that space requires a lock or needs a lock removed, due to a past-due or newly paid balance." },
            { name: "Insurance", type: "insurance",  key: 'insurance', description: "The Insurance report includes a detailed list of insured tenants with their premium costs and coverage amounts." },
            { name: "Failed Payments", type: "failed-payments",  key: 'failed_payments', description: "The Failed Payments report lists all the transactions that ran and failed." },
            { name: "Merchandise Sales", type: "merchandise-sales", key: 'merchandise_sales', description:  "The Merchandise Sales report provides a list of merchandise you've sold at your property." },
            { name: "Autopay", type: "autopay", key: 'autopay', description:  "The AutoPay report displays the payments that are using AutoPay, and the next bill date on the lease. This report does not provide historical payments." },
            { name: "Auction List", type: "auction-list",  key: 'auction_list', description: "The Auction List report gives you a view of your auctions, their statuses, and other relevant auction information." },
            { name: "Space Details", type: "space-deatils",  key: 'space_details', description: "The Space Details report provides fine details about every space at your properties. Use this report to view information such as how many of your spaces are vacant, promotions tied to certain spaces, spaces with delinquent tenants, and more." },
            { name: "Tenant Emails", type: "tenant-emails", key: 'tenant_emails', description: "The Tenant Emails report will show you a list of all current and past tenants alongside their emails. Use this report to gather your tenants' emails in one place to quickly send them an email."},
            { name: "Processed Cash Transactions", type: "cash-payments",  key: 'cash_payments', description: "The Processed Cash Transactions report provides a list of all successful cash transactions at your properties." },
            { name: "Processed Credit Card Transactions", type: "credit-card-payments",  key: 'credit_card_payments', description: "The Processed Credit Card Transactions report provides a list of all successful credit card transactions at your properties. The report shows transaction details such as the type of card used, the last four digits of the card, the amount charged, and more." },
            { name: "Processed ACH Transactions", type: "ach-payments",  key: 'ach_payments', description: "The Processed ACH Transactions report provides a list of all successful ACH transactions at your properties. The report shows transaction details such as when the transaction was processed, the last four digits of the account, the amount charged, and more." },
            { name: "Voided Payments", type: "void-payments",  key: 'void_payments', description: "The Void Payments report tracks all voided payments. Use this report to review how many payments your manager has voided." },
            { name: "Refunds", type: "refunds",  key: 'refunds', description: "The Refunds Report allows you to view all of the refunds you've given out. Use this report to keep track of how much money you have refunded to tenants." },
            { name: "Protection or Insurance Status", type: "insurance-status",  key: 'insurance_status', description: "The Protection/Insurance Status report gives you the insurance status of all current tenants. Use this report to easily view which of your tenants are enrolled in insurance and which are not." },
            { name: "Discounts and Promotions", type: "discounts-and-promotions",  key: 'discounts_and_promotions', description: "The Discounts and Promotions report lets you see how many discounts have been given out during a specified time frame. use this report to calculate your cost to convert prospects into tenants." },
            { name: "Active Tenant Directory", type: "active-tenant-directory",  key: 'active_tenant_directory', description: "The Active Tenant Directory provides information for all your active tenants as of today. Use this report to quickly access your tenant's information such as their name, space number, address, phone number, status, and total past due." },
            { name: "Move-out with Reason", type: "move-out",  key: 'move_out', description: "This is a report where we can see why tenants are moving out, so that we can get feedback on how to retain tenants." },
            { name: "Completed Tasks", type: "completed-tasks", key: 'completed_tasks', description: "The Completed Tasks report shows every completed task for all the people using Hummingbird at your company. Use this report to verify if store managers are completing all the daily tasks necessary to effectively manage your properties." },
            { name: "Incomplete Tasks", type: "incomplete-tasks", key: 'incomplete_tasks', description: "The Completed Tasks report shows every completed task for all the people using Hummingbird at your company. Use this report to verify if store managers are completing all the daily tasks necessary to effectively manage your properties." },
            { name: "All Tasks", type: "all-tasks", key: 'all_tasks', description: "The All Tasks report shows all tasks for all the people using Hummingbird at your company. Use this report to verify if store managers are completing all the daily tasks necessary to effectively manage your properties." },
            { name: "Scheduled Move-out with Reason", type: "move-out-tasks", key: 'move_out_task', description: "The Scheduled Move-Out report shows you tenants who are scheduled to move out, as well as a reason for why they're moving out. Use this report to easily get an idea of how many of your tenants are planning to move out." },
            { name: "Tenant Billing", type: "tenant-billing",  key: 'tenant_billing', description: "The Tenant Billing report provides billing-related details about all current and future tenants. Use this report to view information such as billing type, rental amount, next billing date, paid through date, and more." },
            { name: "Voided Invoices", type: "void-invoices",  key: 'void_invoices', description: "The Voided Invoices report provides a list of the invoices you've voided at your properties. Use this report to see when an invoice was voided, the amount that was voided, who voided the invoice, and more." },
            { name: "Bad Debt", type: "bad-debt",  key: 'bad_debt', description: "The Bad Debt report provides a list of all the products you have written off. Use this report to measure how much money you've lost due to forgiving charges." },
            { name: "Lead Activity - Modified Date", type: "lead-activity",  key: 'lead_activity', description: "View all leads and their activities, driven by the Lead Modified Date to reflect the latest updates." },
            { name: "Pending Move-In Activity", type: "pending-move-in-activity",  key: 'pending_move_in_activity', description: "View a record of your current and past pending move-ins from XPS. Use this report to better understand which leads get converted to determine the effectiveness of your XPS call center. " },
            { name: "Paused Delinquencies", type: "delinquency_pauses",  key: 'delinquency_pauses', description: "Report of paused delinquencies." },
            { name: "Delinquencies", type: "delinquency",  key: 'delinquency', description: "Report of delinquencies." },
            { name: "Failed Delinquency Actions", type: "delinquency_failed_actions",  key: 'delinquency_failed_actions', description: "Report of failures in the delinquency processes." },
            { name: "Tenant Additional Contacts", type: "additional-contacts", key: 'additional_contacts', description: "The Tenant Additional Contacts report will show you a list of all current tenants alongside their additional contacts. Use this report to gather your tenant's additional contacts in one place."},
            { name: "property_phone_calls", type: "property_phone_calls", key: 'property_phone_calls', description: "This report generates all the phone call records of selected properties"},
            { name: "Active National Accounts", type: "active_national_accounts", key: 'active_national_accounts', description: "Active National Accounts in System"},
            { name: "Processed Direct Debit Transactions", type: "direct-debit-payments",  key: 'direct_debit_payments', description: "The Processed Direct Debit Transactions report provides a list of all successful Direct Debit transactions at your properties. The report shows transaction details such as when the transaction was processed, the last four digits of the account, the amount charged, and more." },
            { name: "Processed EFTPOS Transactions", type: "eftpos-payments",  key: 'eftpos_payments', description: "The Processed EFTPOS Transactions report provides a list of all successful EFTPOS transactions at your properties. The report shows transaction details such as when the transaction was processed, the type of card used, the amount charged, and more." },
            { name: "Reverted Payment Cycles", type: "reverted-payment-cycles", key: 'reverted_payment_cycles', description:  "The report shows the details of all the payment cycles that were either reverted or cancelled." },
            { name: "Reversed Move-outs", type: "reversed-move-outs", key: 'reversed_move_outs', description:  "The report shows the details of all the move-outs that have been reversed." },
            { name: "Lead Activity - Created Date", type: "lead-activity-created-date",  key: 'lead_activity_created_date', description: "View all leads and their activities, driven by the Lead Created Date to reflect when they were first created." },
            { name: "Free Insurance", type: "free_insurance", key: 'free_insurance', description:  "The report shows the details of tenants enrolled in free insurance." },
          ]
    };
  },
  props: ['savedReports'], 
  created(){
      console.log("savedReports", this.savedReports);

  },
  computed:{
    sorted_list(){
      return this.report_library.concat(this.savedReports).sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    goToReport(report) {
      try {
        if(report.id){
          this.$router.push("reports/view/" + report.id);
        } else {
          this.$router.push("reports/" + report.type);
        }



        
      } catch (err) {}
    }
  },

};
</script>
<style lang="scss">
.card-title {
  font-size: 14px;
  line-height: 24px;
  color: #212b36;
}
.reports-library-v-card-text {
  height:106px;
  overflow:scroll;
  line-height:16px;
}
.card-description {
  font-size: 12px;
  line-height: 16px;
  color: #474f5a;
}
.btn-style {
  font-weight: normal;
  letter-spacing: normal;
}
.secondary-button {
  background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
  border: 1px solid #c4cdd5;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
}
.primary-button {
  background: linear-gradient(180deg, #47c0bf -70%, #00848e 126.25%);
  border: 1px solid #00848e;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
  color: #ffffff;
}
</style>
